import React from 'react';
import styles from './Brands.module.css';
import gatetheatre from '../../images/clients/gatetheatre.jpg';
import imagefitness from '../../images/clients/imagefitness.jpg';
import jodz from '../../images/clients/jodz.jpg';
import studiocoffee from '../../images/clients/studiocoffee.jpg';
import triequestrian from '../../images/clients/triequestrian.jpg';
import wakingdreams from '../../images/clients/wakingdreams.jpg';

function Brands() {
    const brands = [
        {
            name: 'The Gate Theatre',
            instagramLink: 'https://instagram.com/gatetheatredublin',
            logoUrl: gatetheatre,
        },
        {
            name: 'Image Fitness',
            instagramLink: 'https://instagram.com/imagefitnesstrainingofficial',
            logoUrl: imagefitness,
        },
        {
            name: 'The Studio Coffee Roasters',
            instagramLink: 'https://instagram.com/thestudiocoffee',
            logoUrl: studiocoffee,
        },
        {
            name: 'Waking Dreams Media',
            instagramLink: 'https://instagram.com/wakingdreamsmedia',
            logoUrl: wakingdreams,
        },
        {
            name: 'TRI Equestrian',
            instagramLink: 'https://instagram.com/triequestriancurragh',
            logoUrl: triequestrian,
        },
        {
            name: 'JOD-Z',
            instagramLink: 'https://instagram.com/jodz_vibz',
            logoUrl: jodz,
        },
    ];

    return (
        <div className={styles.brandsSection}>
            <p className="genericText" style={{fontWeight: 'bold'}}>Brands I've Worked With</p>
            <div className={styles.brandsGrid}>
            {brands.map((brand) => (
                <div key={brand.name} className={styles.brandItem}>
                <a href={brand.instagramLink} target="_blank" rel="noopener noreferrer">
                    <img src={brand.logoUrl} alt={brand.name} className={styles.brandLogo} />
                </a>
                </div>
            ))}
            </div>
        </div>
    );
}

export default Brands;