import React from 'react';
import Navbar from './components/Navbar/Navbar.tsx';
import Footer from './components/Footer/Footer.tsx';
import Home from './sections/Home/Home.tsx';
import About from './sections/About/About.tsx';
import Portfolio from './sections/Portfolio/Portfolio.tsx';
import Contact from './sections/Contact/Contact.tsx';

function App() {
  return (
    <div>
      <Navbar/>
      <Home/>
      <About/>
      <Portfolio/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
