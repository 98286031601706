import React from 'react';
import styles from './About.module.css';
import about from '../../images/about-me-photo.jpg';
import Brands from '../../components/Brands/Brands.tsx';

function About() {  
    return (
        <div id="about" className={`${styles.section} ${styles.sectionSplit}`}>
        <div className={`${styles.split} ${styles.left}`}>
          <h2 className="heading">About Me</h2>
          <img src={about} alt="about-me-photo" className={styles.aboutMeImgMobile}></img>
          <p className="genericText">
            I'm a passionate videographer & photographer based in Dublin with a background in post-production and extensive experience in live event videography, multi-camera setups, and studio productions.
            My mission is to craft compelling narratives that resonate and evoke emotion, whilst making the process seamless and hassle-free for my clients.<br></br>
          </p>
          <br></br>
          <Brands/>
        </div>
        <div className={`${styles.split} ${styles.right}`}>
          <img src={about} alt="about-me-photo" className={styles.aboutMeImg}></img>
        </div>
      </div>
    );
}

export default About;