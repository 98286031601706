import React from 'react';
import styles from './Home.module.css';
import logo from '../../images/Logo-transparency.png';

function Home() {
    return (
        <div id="home" className="section">
            <img src={logo} alt="Lorcan B Media" className={styles.logo}></img>
            <p className={styles.services}>Promotional & Commercial Videos • Post-Production Editing • Event Videography • Photography Services</p>
            <a href="#contact" className={styles.contactButton}>Get in Touch</a>
        </div>
    );
}

export default Home;