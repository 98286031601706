import React, { useState } from 'react';
import styles from './Portfolio.module.css';

function Portfolio() {
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const videos = [
        { id: 1, title: "Image Fitness", subtitle: "Global Promo Reveal Trailer", url: "https://www.youtube.com/watch?v=jOYuppIKKv8" },
        { id: 2, title: "Studio Coffee Roasters", subtitle: "Gifted Craft Fair RDS", url: "https://www.youtube.com/watch?v=T8GOTToZqUA" },
        { id: 3, title: "Image Fitness", subtitle: "Pilates and Weightlifting Course", url: "https://www.youtube.com/watch?v=DtU-tPdB5v0" },
        { id: 4, title: "The Gate Theatre", subtitle: "The Loved Ones Premiere", url: "https://www.youtube.com/watch?v=8qR1qAE0ZXI" },
        { id: 5, title: "Portrait Boss", subtitle: "Behind The Scenes Shoot", url: "https://www.youtube.com/watch?v=-vtBb2duNJw" },
        { id: 6, title: "Waking Dreams Media", subtitle: "IGNITE, IMPACTING SDGs for UCC", url: "https://www.youtube.com/watch?v=9fO6LKZMZW8" },
        { id: 7, title: "Waking Dreams Media", subtitle: "Unleash Potential Marketing Material", url: "https://www.youtube.com/watch?v=KrOIf_7s5SI" },
        { id: 8, title: "Bord Failte", subtitle: "Spec Advertisement", url: "https://www.youtube.com/watch?v=6XKM-ZgffNQ" },
        { id: 9, title: "Irish Whale and Dolphin Group", subtitle: "Advertisement Video", url: "https://www.youtube.com/watch?v=4FUi9DM60HU" },
        { id: 10, title: "Phonewatch", subtitle: "Awards Ceremony 2022 at Mansion House", url: "https://www.youtube.com/watch?v=0EyVeTazw6w" },
    ];
    
    const videosPerPage = 6;
    const totalPages = Math.ceil(videos.length / videosPerPage);

    const indexOfLastVideo = currentPage * videosPerPage;
    const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
    const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo);

    const openVideo = (video) => {
    setSelectedVideo(video);
    };

    const closeVideo = () => {
    setSelectedVideo(null);
    };

    const getYouTubeID = (url) => {
        const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        return match && match[2].length === 11 ? match[2] : null;
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div id="portfolio" className="section">
            <h2 className="heading">Portfolio</h2>
            <div className={styles.videoGrid}>
                {currentVideos.map((video) => {
                const videoID = getYouTubeID(video.url);
                const thumbnailUrl = `https://img.youtube.com/vi/${videoID}/hqdefault.jpg`;
                
                return (
                    <div key={video.id} className={styles.videoItem} onClick={() => openVideo(video)}>
                    <img src={thumbnailUrl} alt={video.title} className={styles.videoThumbnail} />
                    <div className={styles.videoInfo}>
                        <div className={styles.videoTitle}>{video.title}</div>
                        <div className={styles.videoSubtitle}>{video.subtitle}</div>
                    </div>
                    </div>
                );
                })}
            </div>

            <div className={styles.pagination}>
                {[...Array(totalPages).keys()].map(pageNumber => (
                <button
                    key={pageNumber + 1}
                    className={`${styles.pageButton} ${currentPage === pageNumber + 1 ? styles.active : ''}`}
                    onClick={() => handlePageChange(pageNumber + 1)}
                >
                    {pageNumber + 1}
                </button>
                ))}
            </div>
            
            {selectedVideo && (
                <div className={styles.videoModal} onClick={closeVideo}>
                <div className={styles.videoContent} onClick={(e) => e.stopPropagation()}>
                    <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${getYouTubeID(selectedVideo.url)}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    ></iframe>
                    <button className={styles.closeButton} onClick={closeVideo}>X</button>
                </div>
                </div>
            )}
        </div>
    );
}

export default Portfolio;